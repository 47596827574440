import styled from "@emotion/styled";
import { LAYER_ONE } from "constants/z-index";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
import { getComponentShadow, getComponentShadowWithHoverEffect } from "segments/desktop/constants";
import colors from "styles/colors";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
const CARD_WIDTH = 286;
const CARD_BORDER_RADIUS = 8;
const CARD_VERTICAL_PADDING = 32;
const CARD_HORIZONTAL_PADDING = 20;
const CARD_IMAGE_HEIGHT = 100;
export const StyledReviewCardComponent = styled.div`
  width: ${CARD_WIDTH}px;
  z-index: ${LAYER_ONE};
  margin: 32px 10px 0;
  padding: ${CARD_VERTICAL_PADDING}px;
  border-radius: ${CARD_BORDER_RADIUS}px;
  background-color: white;

  ${getComponentShadow()}
  &:hover {
    ${getComponentShadowWithHoverEffect()}
  }

  transition: filter 0.2s ease-in-out;
  color: ${colors[BRAND_BLACK_TEXT_COLOR]};
  position: relative;

  .top-image {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 16px;
    height: ${CARD_IMAGE_HEIGHT}px;

    img {
      max-height: ${CARD_IMAGE_HEIGHT}px;
      max-width: ${CARD_WIDTH - CARD_HORIZONTAL_PADDING * 4}px;
    }
  }

  .title {
    font-size: 1.125rem;
    line-height: 24px;
    letter-spacing: -0.5px;
    font-weight: 500;
    margin-bottom: 24px;
    min-height: 64px;
  }

  .subtitle {
    font-size: 1rem;
    line-height: 24px;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    width: unset;
    text-align: center;
    padding: ${CARD_VERTICAL_PADDING}px;
    filter: unset;

    &:hover {
      filter: unset;
    }

    .title {
      font-size: 1rem;
    }
  }
`;
