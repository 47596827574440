import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledReviewCardsReviewsComponentDesktopComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;

  .full-paragraph-component .full-paragraph-wrapper {
    max-width: unset;
  }

  .cards {
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;
