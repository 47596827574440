import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { LG } from "constants/sizes";
export default {
    fullParagraph: {
        title: "<light>An </light>award-winning <light>platform.</light> Loved <light>by customers.</light>",
        body: "Based on 10,000+ customer reviews.",
        topic: {
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/stars.png",
                alt: "5 full stars rating"
            }
        },
        buttonConfig: {
            buttonType: SIGNUP_BUTTON_TYPE
        },
        textAlign: "center",
        topicBottomMargin: "40px",
        spaceFromParagraph: "40px",
        buttonTopMargin: "44px"
    },
    cards: [
        {
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/trust.png",
                alt: "TrustRadius badge of Best Feature Set 2022"
            },
            title: "Voted best feature set, relationship and value",
            subtitle: "“This is the best no-code platform I've ever seen.”"
        },
        {
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/forrester.png",
                alt: "Forrester logo"
            },
            title: "345% uplift in ROI",
            subtitle: "The Total Economic Impact™ Report"
        },
        {
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/capterra.png",
                alt: "Capterra shortlist 2022 badge"
            },
            title: "Shortlisted in over 8 software categories",
            subtitle: "“The perfect organizer and team builder.”"
        },
        {
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/g2.png",
                alt: "G2 Leader winter 2022 badge"
            },
            title: "Market leader across 18 categories",
            subtitle: "Flexbile product with near endless possibilities."
        }
    ],
    titleSize: LG,
    marginAboveCards: "40px"
};
