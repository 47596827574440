import styled from "@emotion/styled";
export const StyledReviewCardsReviewsComponentMobileComponent = styled.div`
  display: flex;
  flex-direction: column;

  .paragraph-and-topic {
    padding: 0px 32px;

    .topic-wrapper {
      display: flex;
      justify-content: center;
      margin-top: var(--topic-margin);

      img {
        max-width: 124px;
      }
    }

    .carousel-wrapper {
      margin-top: var(--margin-above-cards);
    }
  }

  .card-item {
    padding: 0 20px;

    .card {
      margin-left: 0;
      margin-right: 0;
      padding: 24px;

      .top-image {
        img {
          max-width: 160px;
        }
      }

      .title {
        margin-bottom: 8px;
      }

      .title,
      .subtitle {
        font-size: 0.875rem;
      }
    }
  }
`;
